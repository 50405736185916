<template>

  <div style="padding: 0%; margin: 0px;height: 100%;">
    <Banner01 style="height: 0px;" />
    <el-carousel indicator-position="none" :height="bannerHeight">

      <el-carousel-item v-for="(item,index) in bannerItems" :key="item">
        <!-- <Banner01/> -->
         <div style="position: relative; font-size: 16px;">
          <router-link v-if="index === 0" to="/products/01">
            <el-button  type="info" class="banner_button" style=" left: 15.8%;top: 67.8%; " :style="{ fontSize: bannerFontSize }" >点击查看</el-button>
          </router-link>
          <router-link v-if="index === 1" to="/products/01">
            <el-button class="banner_button" type="info" style=" left: 70.2%;top: 80.5%; " :style="{ fontSize: bannerFontSize }" >点击查看</el-button>
          </router-link>
          <router-link v-if="index === 2" to="/products/02">
            <el-button class="banner_button" type="info" style=" left: 15.8%;top: 67.8%;" :style="{ fontSize: bannerFontSize }" >点击查看</el-button>
          </router-link>
          <el-image style="width: 100%; object-fit: cover; background-color: #fff;z-index: 10; object-fit: cover;" :height="bannerHeight" :src="item">
          </el-image>
        </div>
      </el-carousel-item>
    </el-carousel>

    <el-image
          style="width: 100%; height: auto;background-color: #fff;"
          :src="home_bg_01"
          :fit="fit">
    </el-image>

    
    <!-- <el-image style="width: 100%; height: auto; background-color: #fff;" :src="home_bg_01">
    </el-image> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Banner01 from '@/components/Banner01.vue';
import '@/assets/css/common.css';

// import Header from '@/components/Header.vue';
// import Footer from '@/components/Footer.vue';

export default {
  components: {
    Banner01,
  },
  data() {
    return {
      screenWidth: 500,
      squareUrl: require('@/assets/img/web_logo.jpg'),
      home_bg_01: require('@/assets/img/首页下.webp'),
      home_bg_02: require('@/assets/img/皮秒机器图01.png'),
      home_bg_03: require('@/assets/img/探头01.png'),
      bannerItems: [
        require('@/assets/img/banner01.webp'),
        require('@/assets/img/banner02.webp'),
        require('@/assets/img/banner03.webp'),
      ],
      imageHeight: 100 // 初始化高度
    };
  },
  computed: {
    bannerHeight() {
      return `${780 / 1920 * this.screenWidth}px`;
    },
    bannerFontSize() {
      var fontSize = `${ this.screenWidth/102}px`;
      //alert(fontSize);
      return fontSize;
    }
  },
  mounted() {
    // 首次加载获取宽度
    this.screenWidth = window.innerWidth;
    this.setSize();

    // 屏幕宽度变化时触发
    window.addEventListener('resize', this.setSize);
  },
  beforeUnmount() {
    // 清除事件监听器
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    setSize() {
      this.screenWidth = window.innerWidth;
      // 不需要在这里手动设置 bannerHeight，因为我们使用了 computed 属性
      console.log(this.bannerHeight); // 可以使用 console.log 替换 alert 进行调试
    },
    handleClick2() {
      this.$router.push({ name: 'products' });
    },
  },
};
</script>

<style scoped>
.body {
  margin: 0%;
}

.home2 {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.banner_button{
  position: absolute; 
  z-index: 100;
  width: 10%; 
  font-size: 60%; 
  background-color: black;
  padding: 1% 1%;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 500px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.sp1 {
  color: #AD8765;
  font-size: 32px;
  font-weight: 555;
}

.sp2 {
  color: #000;
  font-size: 32px;
  font-weight: 555;
}

.sp3 {
  color: #000;
  font-size: 26px;
  font-weight: 555;
}

.line-container {
  width: 75%;
  height: 50px;
  border: 1px solid transparent;
  margin: 0 auto;
  position: relative;
}

.line-wrap {
  width: 150px;
  height: 50px;
  /* border: 1px solid green; */
  margin: 0 auto;
  margin-top: 5px;
  z-index: 2;
  font-weight: 550;
  position: relative;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  font-size: 16px;
}

.line {
  width: 100%;
  border: 1px solid #000;
  height: 0px;
  position: absolute;
  left: 50%;
  margin-left: -50%;
  top: 28px;
  z-index: -1;
}


.textCard {
  width: 80%;
  height: 90px;
  background: #AD8765;
  border-radius: 10px 10px 10px 10px;
  margin: 5%;
  padding-left: 10px;
  padding-right: 10px;
}

.order {
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 5px;
}

.order .line {
  display: inline-block;
  width: 40%;
  border-top: 1px solid #000;
}

.order .txt {
  color: #686868;
  vertical-align: -8%;
  /* vertical-align: -4px; */
  width: 142px;
  height: 34px;
  font-family: HiraginoSansGB, HiraginoSansGB;
  font-weight: normal;
  font-size: 16px;
  color: #323232;
  line-height: 60px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}

.textItem1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  color: #fff;
  text-align: center;
  font-size: .8rem;
}

.textItem2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  color: #fff;
  text-align: center;
  font-size: 2rem;
}

.textItem3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 70px;
  width: auto;
  height: 100%;
  color: #fff;
  font-size: .6rem;
}

.slogin {
  padding: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  color: #000;
  font-size: 14px;
}
</style>