<template>
      <!-- <el-row style="height: 60px;">
        <el-col :span="6" class="logo-container">
          <router-link to="/">
            <el-image
              style="width: 50px; height: 50px"
              :size="50"
              :src="squareUrl"></el-image>
          </router-link>
        </el-col>
        <el-col :span="18">
          <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item v-for="(item, index) in menuItems" :key="index" :index="item.link" :router="true">
              {{ item.text }}
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row> -->
      <el-image
        style="width: 100%; height: 400px; background-color: #fff;"
        :src="squareUrl">
      </el-image>
    
  </template>
  
  <script>
        //   { text: '联系我们', link: '/contact' },
  export default {
    name: 'Banner01',
    data() {
      return {
        squareUrl: require("@/assets/img/web_logo.jpg"),
        menuItems: [
          { text: '首页', link: '/' },
          { text: '关于我们', link: '/about' },
          { text: '产品中心', link: '/products' },
          { text: '客户服务', link: '/service' },
          { text: '品牌故事', link: '/brand' },
        ],
        hoveredIndex: null,
      };
    },
    methods: {
        handleSelect(key, keyPath){
            if (key !== this.$route.path) {
                this.$router.push(key);
            }
        },
      onMouseEnter(index) {
        this.hoveredIndex = index;
      },
      onMouseLeave() {
        this.hoveredIndex = null;
      },
    },
  };
  </script>
  
  <style scoped>
  .header {
    padding: 0px;
    background-color: #fff;
    height: 60px;
  }
  
  </style >